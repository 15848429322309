import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  Section,
  Container,
  SectionLineDescription,
  SectionTitle,
  SectionPageTitle,
} from "../components/Section";
import { HeroBanner, HeroImage } from "../components/HeroBanner";
import Badge from "../components/Badge";
import ProductCarousel from "../components/ProductCarousel";
import ServiceBanner from "../images/service-banner.jpg";

const HeroBanners = styled(HeroBanner)`
  @media (min-width: 1600px) {
    height: 800px;
  }
`;
const HeroCaption = styled.div`
  background-color: #e9242a;
  color: #fff;
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 10px 20px 5px 20px;
  margin-bottom: 30px;
  @media (min-width: 1200px) {
    padding: 15px 30px 10px 30px;
    margin-bottom: 60px;
  }
  @media (min-width: 1600px) {
    padding: 20px 60px 10px 60px;
  }
`;

const ListBadge = styled.div`
  padding: 30px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -5px;
  .badge {
    margin: 5px;
  }
`;

const ServiceArea = ({ location, data }) => {
  const states = data.allContentfulStatePages.edges;

  return (
    <Layout location={location}>
      <Seo
        title={`Service Area - We Deliver Across ${states.length} States and 1800 Cities`}
        description={`We deliver wide range of metal buildings including metal garages, barns, carports, metal buildings, kits, and more, across the ${states.length} states. Call now to order!`}
      />
      <HeroBanners>
        <HeroImage opacity="1" bgImg={`url(${ServiceBanner})`}></HeroImage>
      </HeroBanners>
      <Section
        ept="0"
        epb="60px"
        xpt="0"
        xpb="40px"
        pt="0"
        pb="30px"
        bgColor="#fff"
        className="section-about"
      >
        <Container>
          <HeroCaption>
            <SectionPageTitle textAlign="center" mb="0" color="#fff">
              We Cover {states.length} States & 18000+ Cities
            </SectionPageTitle>
          </HeroCaption>
        </Container>
        <SectionLineDescription mb="0" textAlign="center">
          <Container>
            <SectionTitle textAlign="center">
              Explore {states.length} States
            </SectionTitle>
            <p>
              We have a network of distribution facilities located across the
              USA to better distribute our products.
            </p>
            {/* <ListBadge>
              {['Alabama', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'].map(item => (
                <div className="badge-gray" aria-hidden="true"><Badge badgeText={item} /></div>
                ))}                 
            </ListBadge> */}
            <ListBadge>
              {states.map((item) => {
                const url = item.node.stateName
                  .split(" ")
                  .join("-")
                  .toLowerCase();
                return (
                  <Link to={`/${url}`}>
                    <div className="badge-gray" aria-hidden="true">
                      <Badge badgeText={item.node.stateName} />
                    </div>
                  </Link>
                );
              })}
            </ListBadge>
          </Container>
        </SectionLineDescription>
      </Section>
      <ProductCarousel data={data.allContentfulProduct.edges} />
    </Layout>
  );
};
export default ServiceArea;

export const pageQuery = graphql`
  query ServiceAreaPageQuery {
    allContentfulProduct(sort: { fields: skuNumber, order: ASC }) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
    allContentfulStatePages {
      edges {
        node {
          stateName
        }
      }
    }
  }
`;
